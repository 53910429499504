import { createSlice } from "@reduxjs/toolkit";

export interface IAuthWindow {
  isOpen: boolean;
}

const initialState: IAuthWindow = { isOpen: false };

export const authWindowSlice = createSlice({
  name: "authWindows",
  initialState,
  reducers: {
    authWindowsOpen: (state) => {
      state.isOpen = true;
    },
    authWindowsClose: (state) => {
      state.isOpen = false;
    },
  },
});

export const {authWindowsClose, authWindowsOpen} = authWindowSlice.actions