import axios from "axios";
import { changeToken } from "./RefreshApi";
import { store } from "../Redux/Store";

export const applicationApiInstance = axios.create({
  baseURL: `https://backendapi.fanaloka.ru/Catalogue/`,
  headers: { "Content-Type": "application/json" },
});

applicationApiInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        await changeToken();
        if (store.getState().auth.isLogin) {
          const access = store.getState().auth.access.token;
          error.config.headers.Authorization = "Bearer " + access;
          return applicationApiInstance.request(error.config);
        }
      } else {
        return Promise.reject(error);
      }
    }
  }
);

applicationApiInstance.interceptors.request.use((config) => {
  const access = store.getState().auth.access.token;
  config.headers.Authorization = "Bearer " + access;
  return config;
});
