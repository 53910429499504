import { configureStore } from "@reduxjs/toolkit";
import { authSlice } from "./Auth/AuthSlice";
import { authWindowSlice } from "./Auth/AuthWindowsSlice";
import { setupTabSlice } from "./Setup/SetupTabSlice";

export const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    authWindow: authWindowSlice.reducer,
    setupTab: setupTabSlice.reducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
