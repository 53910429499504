import {
  Alert,
  Button,
  Col,
  Container,
  FloatingLabel,
  Form,
  Modal,
  Row,
  Spinner,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../Redux/Store";
import { authWindowsClose } from "../../Redux/Auth/AuthWindowsSlice";
import { IError, LoginApi } from "../../Api/LoginApi";
import { setAccess, setRefresh } from "../../Redux/Auth/AuthSlice";
import { useState } from "react";

export function AuthWindow() {
  const show = useSelector((state: RootState) => state.authWindow.isOpen);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [loginString, setLoginstring] = useState<string>("");
  const [passwordString, setPasswordString] = useState<string>("");

  const [isError, setIsError] = useState<IError>({
    isError: false,
    errorString: "",
  });

  const disableButton =
    loginString === "" || passwordString === "" ? true : false;

  const dispatch = useDispatch();
  const handleClose = () => dispatch(authWindowsClose());

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <Container>
              <Row>
                <Col sm={1}>{isLoading && <Spinner animation="border" />}</Col>
                <Col sm="auto">
                  <Modal.Title className="mx-3">Вход в систему</Modal.Title>
                </Col>
              </Row>
            </Container>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isError.isError && (
            <Alert key={"danger"} variant={"danger"}>
              {isError.errorString}
            </Alert>
          )}
          <FloatingLabel
            controlId="floatingInput"
            label="Email address"
            className="mb-3"
          >
            <Form.Control
              value={loginString}
              onChange={(e) => setLoginstring(e.target.value)}
              type="email"
              placeholder="name@example.com"
            />
          </FloatingLabel>
          <FloatingLabel controlId="floatingPassword" label="Password">
            <Form.Control
              value={passwordString}
              onChange={(e) => setPasswordString(e.target.value)}
              type="password"
              placeholder="Password"
            />
          </FloatingLabel>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Закрыть
          </Button>
          <Button
            variant="primary"
            disabled={disableButton}
            onClick={async () => {
              await LoginRequest();
            }}
          >
            Войти
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );

  async function LoginRequest() {
    setIsLoading(true);
    await LoginApi({
      email: loginString,
      password: passwordString,
    })
      .then((response) => {
        dispatch(setRefresh(response.refresh));
        dispatch(setAccess(response.access));
        SetDefaultStateWindows();
        handleClose();
      })
      .catch((error: IError) => setIsError(error));
    setIsLoading(false);
  }

  function SetDefaultStateWindows() {
    setLoginstring("");
    setPasswordString("");
    setIsError({ isError: false, errorString: "" });
  }
}
