import { useState } from "react";
import {
  Button,
  Card,
  FloatingLabel,
  Form,
  FormFloating,
  Modal,
} from "react-bootstrap";

interface IClient {
  email: string;
  userName: string;
  surName: string;
  firstName: string;
  middleName: string;
  Phone: string;
  index: string;
  region: string;
  locality: string;
  street: string;
  house: string;
  frame: string;
  apartment: string;
  placeOfWork: string;
  specialMarks: string;
  discountProgram: string;
  comments: string;
  attachments: string;
}

export function AddClient() {
  const [client, setClient] = useState<IClient>(SetDefaultClientdata);

  return (
    <>
      <Card className="mx-2 my-2">
        <Card.Body>
          <Form.Control
            className="mb-2"
            type="password"
            id="userName"
            disabled={true}
          />
          <FloatingLabel
            className="mb-2"
            controlId="floatingSurName"
            label="Фамилия"
          >
            <Form.Control type="text" placeholder="Фамилия" />
          </FloatingLabel>
          <FloatingLabel
            className="mb-2"
            controlId="floatingFirstName"
            label="Имя"
          >
            <Form.Control type="text" placeholder="Имя" />
          </FloatingLabel>
          <FloatingLabel
            className="mb-2"
            controlId="floatingMiddleName"
            label="Отчество"
          >
            <Form.Control type="text" placeholder="Отчество" />
          </FloatingLabel>
          <FloatingLabel
            className="mt-5 mb-2"
            controlId="floatingPhone"
            label="Телефон"
          >
            <Form.Control type="text" placeholder="Телефон" />
          </FloatingLabel>
        </Card.Body>
      </Card>
    </>
  );
}

function SetDefaultClientdata(): IClient {
  const client: IClient = {
    email: "",
    userName: "",
    surName: "",
    firstName: "",
    middleName: "",
    Phone: "",
    index: "",
    region: "",
    locality: "",
    street: "",
    house: "",
    frame: "",
    apartment: "",
    placeOfWork: "",
    specialMarks: "",
    discountProgram: "",
    comments: "",
    attachments: "",
  };

  return client;
}
