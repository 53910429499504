import axios from "axios";
import { store } from "../Redux/Store";
import { logout, setAccess, setRefresh } from "../Redux/Auth/AuthSlice";

const refreshInstance = axios.create({
  baseURL: `https://backendapi.fanaloka.ru/User/`,
  headers: { "Content-Type": "application/json" },
});

refreshInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        console.log("refresh -> 401");
      }
    }
    return Promise.reject(error);
  }
);

refreshInstance.interceptors.request.use((config) => {
  const refresh = store.getState().auth.refresh.token;
  config.headers.Authorization = "Bearer " + refresh;
  return config;
});

export function changeToken() {
  return refreshInstance
    .post("ChangeRefreshToken")
    .then((response) => {
      console.log(response);
      store.dispatch(setRefresh(response.data.refresh));
      store.dispatch(setAccess(response.data.access));
    })
    .catch((error) => {
      store.dispatch(logout());
    });
}
