import { Button, Container, Nav, NavDropdown, Navbar } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { authWindowsOpen } from "../../Redux/Auth/AuthWindowsSlice";
import { AuthWindow } from "../Auth/AuthWindow";
import { RootState } from "../../Redux/Store";
import { getPetType } from "../../Api/PetTypeApi/GetPetTypeApi";
import { logout } from "../../Redux/Auth/AuthSlice";

export function NavBar() {
  const dispatch = useDispatch();

  const isAuth = useSelector((state: RootState) => state.auth.isLogin);
  const buttonText = !isAuth ? "LogIn" : "LogOut";
  const buttonFunction = ReturnButtonFunction();

  return (
    <>
      <AuthWindow />
      <Navbar bg="light" expand="lg">
        <Container fluid>
          <Navbar.Brand as={NavLink} to="/">
            Кот и Дог
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="me-auto my-2 my-lg-0"
              style={{ maxHeight: "100px" }}
              navbarScroll
            >
              <Nav.Link as={NavLink} to="/СlientСards">Карты клиентов</Nav.Link>
              <Nav.Link as={NavLink} to="/Setup">Setup</Nav.Link>
              <Nav.Link href="#action1">Home</Nav.Link>
              <Nav.Link href="#action2">Link</Nav.Link>
              <NavDropdown title="Link" id="navbarScrollingDropdown">
                <NavDropdown.Item href="#action3">Action</NavDropdown.Item>
                <NavDropdown.Item href="#action4">
                  Another action
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="#action5">
                  Something else here
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link href="#" disabled>
                Link
              </Nav.Link>
            </Nav>
            <Button variant="outline-success" onClick={buttonFunction}>
              {buttonText}
            </Button>
            <Button
              variant="outline-success"
              onClick={() => {
                getPetType({
                  name: "",
                });
              }}
            >
              Test
            </Button>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );

  function ReturnButtonFunction() {
    return !isAuth
      ? () => dispatch(authWindowsOpen())
      : () => dispatch(logout());
  }
}
