import axios, { AxiosError } from "axios";

export interface ILogin {
  email: string;
  password: string;
}

export interface ILoginToken {
  access: string;
  refresh: string;
}

export interface IError {
  isError: boolean;
  errorString: string;
}

export async function LoginApi(request: ILogin) {
  return await axios
    .post<ILoginToken>(`https://backendapi.fanaloka.ru/User/Login`, request)
    .then((response) => response.data)
    .catch((error: AxiosError) => {
      if (error.response?.status === 401) {
        const err: IError = {
          isError: true,
          errorString: "Неверный логин или пароль",
        };
        throw err;
      } else {
        const err: IError = {
          isError: true,
          errorString: "Неизвестная ошибка",
        };
        throw err;
      }
    });
}
