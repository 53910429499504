import { applicationApiInstance } from "../ApplicationApi";

export interface IPetTypeRequest {
    name: string;
  }
  
  export interface IPetTypeResponse {
    id: number;
    name: string;
    pets: null;
    breeds: null;
  }
  
  export function getPetType(request: IPetTypeRequest) {
    return applicationApiInstance.post("GetPetType", request);
  }