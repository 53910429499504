import { Button, Card, Col, Container, Form, Row, Tab, Tabs } from "react-bootstrap";
import { AddClient } from "./AddClient";
import { SearchCard } from "./SearchCard";

export function СlientСards() {
  return (
    <>
      <Card className="mx-2 my-2">
        <Tabs
          defaultActiveKey="profile"
          id="uncontrolled-tab-example"
          className="mt-2"
        >
          <Tab eventKey="visits" title="Открытые приемы">
          </Tab>
          <Tab eventKey="openСards" title="Открытые карты">
          </Tab>
          <Tab eventKey="searchCard" title="Поиск карт">
            <SearchCard/>
          </Tab>
          <Tab eventKey="addСlient" title="Добавить клиента">
            <AddClient />
          </Tab>
        </Tabs>
      </Card>
    </>
  );
}
