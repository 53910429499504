import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface ISetupTab {
  tab: string;
}

const initialState: ISetupTab = { tab: "petType" };

export const setupTabSlice = createSlice({
  name: "setupTabSlice",
  initialState,
  reducers: {
    setSetupTab: (state, action: PayloadAction<string>) => {
      state.tab= action.payload;
    },
  },
});

export const { setSetupTab } = setupTabSlice.actions;
