import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import jwtDecode, { JwtPayload } from "jwt-decode";

export interface IAuth {
  isLogin: boolean;
  clientId: string;
  clientName: string;
  isAdmin: boolean;
  isEmployee: boolean;
  access: IToken;
  refresh: IToken;
}

enum Role {
  administrator = "administrator",
  employee = "employee",
  client = "client",
}

interface JWT extends JwtPayload {
  "http://schemas.microsoft.com/ws/2008/06/identity/claims/role": Role[];
  "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name": string;
  id: string;
}

export interface IToken {
  isPresent: boolean;
  token: string;
}

const initialState: IAuth = {
  isLogin: false,
  clientId: "",
  clientName: "",
  isAdmin: false,
  isEmployee: false,
  access: { isPresent: false, token: "" },
  refresh: { isPresent: false, token: "" },
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state) => {
      state.isLogin = true;
    },
    logout: (state) => {
      state.isLogin = false;
    },
    setRefresh: (state, action: PayloadAction<string>) => {
      const refresh = action.payload;
      const decode: JWT = jwtDecode(refresh);
      const roles =
        decode["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];

      for (const role of roles) {
        if (role === Role.administrator) console.log("Администратор есть");
      }
      state.isLogin = true;
      state.refresh.isPresent = true;
      state.refresh.token = action.payload;
    },
    setAccess: (state, action: PayloadAction<string>) => {
      state.access.isPresent = true;
      state.access.token = action.payload;
    },
  },
});

export const { login, logout, setRefresh, setAccess } = authSlice.actions;
