import { Tab, Tabs } from "react-bootstrap";
import { RootState } from "../../Redux/Store";
import { useDispatch, useSelector } from "react-redux";
import { setSetupTab } from "../../Redux/Setup/SetupTabSlice";

export function Setup() {
  const dispatch = useDispatch();
  const key = useSelector((state: RootState) => state.setupTab.tab);
  return (
    <Tabs
      id="controlled-tab-example"
      activeKey={key}
      onSelect={(k) => dispatch(setSetupTab(k !== null ? k : "petType"))}
      className="mb-3 mx-3"
    >
      <Tab eventKey="petType" title="Вид животного"></Tab>
      <Tab eventKey="profile" title="Profile"></Tab>
      <Tab eventKey="contact" title="Contact"></Tab>
    </Tabs>
  );
}
