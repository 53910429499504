import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { NavBar } from "./Сomponents/NavBar/NavBar";
import { NotFound } from "./Сomponents/NotFound";
import { Home } from "./Сomponents/Home/Home";
import { Setup } from "./Сomponents/Setup/Setup";
import { СlientСards } from "./Сomponents/СlientСards/СlientСards";

function App() {
  return (
    <>
      <BrowserRouter>
        <NavBar />
        <Routes>
          <Route path="*" element={<NotFound />} />
          <Route path="/" element={<Home />} />
          <Route path="/Setup" element={<Setup />} />
          <Route path="/СlientСards" element={<СlientСards />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
